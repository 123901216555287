body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Askan Regular";
  src: local("Askan Regular"),
    url("./Fonts/Askan_Regular.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Askan Bold";
  src: local("Askan Bold"), url("./Fonts/Askan_Bold.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Askan DemiBold";
  src: local("Askan DemiBold"),
    url("./Fonts/Askan_DemiBold.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Askan Light";
  src: local("Askan Light"), url("./Fonts/Askan_Light.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Lato Regular";
  src: local("Lato Regular"), url("./Fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Lato Bold";
  src: local("Lato Bold"), url("./Fonts/Lato-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Merriweather Light";
  src: local("Merriweather Light"),
    url("./Fonts/Merriweather-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Merriweather Regular";
  src: local("Merriweather Regular"),
    url("./Fonts/Merriweather-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Merriweather Bold";
  src: local("Merriweather Bold"),
    url("./Fonts/Merriweather-Bold.ttf") format("truetype");
  font-weight: normal;
}
