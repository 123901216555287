/* Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal {
  background: #fff;
  border-radius: 8px;
  width: 400px;
  padding: 20px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  max-height: calc(100% - 70px);
  overflow-y: scroll;
}

/* Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #8e8e9366;
  margin-bottom: 15px;
  padding-bottom: 12px;
}

.logo-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-around;
}
.logo-header img {
  width: 140px;
}
.logo-header h2 {
  color: #1a394f;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.modal-header h2 {
  font-size: 18px;
  margin: 0;
}

.close-btn {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

/* Section */
.modal-section {
  margin-bottom: 15px;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}
.radio-group-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.radio-group-icon img {
  width: 20px;
}
.radio-group-icon span {
  color: #1a394f;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
.main-banks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}
.bank-container {
  flex: 1;
}
.account-number h2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.account-number img {
  width: 22px;
}
.bank-container h2 {
  color: #1a394f;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  margin-bottom: 4px !important;
  margin-top: 0 !important;
}
.bank-account {
  background: #e2ddd0;
  padding: 10px !important;
  border-radius: 10px;
  text-align: left;
  font-family: monospace;
  font-size: 14px;
  border: 1px solid #a5a9ac33;
  color: #101316;
  font-weight: 600;
}

.transfer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.transfer p,
.instapay h5 {
  color: #1a394f;
  font-size: 14px;
  font-weight: 500;
  margin: 0 !important;
  line-height: 30px;
}

.instapay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
}
.instapay img {
  width: 24px;
}

.transfer-text {
  margin: 10px 0 5px;
  font-size: 14px;
}

/* Proof Section */

.proof-section {
  background: #f0f0f0;
  padding: 10px !important;
  border-radius: 4px;
  border: 1px solid #d2d2d4;
  color: #1a394f;
  margin-bottom: 10px;
}
.proof-section p {
  color: #1a394f;
  font-size: 14px;
  font-weight: 500;
  margin: 0 !important;
  line-height: 20px;
  display: flex;
  margin-bottom: 10px !important;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  margin-top: 10px;
}
.contact-info h2 {
  margin: 0 !important;
  color: #1a394f;
  font-size: 12px;
  font-weight: 600;
}
.contact-info div {
  background: #e2ddd099;
  padding: 10px !important;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  width: -webkit-fill-available;
  border: 1px solid #a5a9ac33;
}
.contact-info div img {
  width: 20px;
}
.contact-info div h5 {
  margin: 0 !important;
  color: #101316b2;
  font-size: 14px;
  font-weight: 600;
}

.or-divider {
  text-align: center;
  margin: 8px 0;
  font-size: 14px;
  color: #777;
}

/* Online Payment */
.online-payment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
  padding: 10px 0 !important;
  border-top: 1px solid #8e8e9366;
  flex-wrap: wrap;
}
.online-payment .pay-phone {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.not-available {
  color: #777;
}

.currently-unavailable {
  color: red;
  font-size: 12px;
}

/* Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.done-btn {
  background: #1a394f;
  color: #fff;
  border: none;
  padding: 10px 20px !important;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.final-button {
  background: #1a394f;
  color: #fff;
  border: none;
  min-width: 130px;
  height: 35px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  font-size: 11px;
}
.final-msg {
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.text-alert {
  margin-top: 4px;
  display: block;
}
.action-table-booking {
  margin-top: 15px !important;
}
.done-btn:hover .final-button:hover {
  background: #1a394fcf;
}

.done-btn:hover {
  background: #083a9f;
}

/* Submit Form Button */
.submit-btn {
  background: green;
  color: white;
  border: none;
  padding: 8px 16px !important;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background: darkgreen;
}

/* Style for the disabled radio button */
input[type="radio"] {
  -webkit-appearance: none; /* Remove default styling */
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #5555551a;
  background-color: #fff;
  cursor: not-allowed;
  position: relative;
}

input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #0f2214;
}

.btns-final {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
